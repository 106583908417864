/* eslint react-hooks/exhaustive-deps: warn */

import React, { lazy, memo, useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
  useParams,
} from 'react-router-dom';

import Meta from '../meta/Meta';
import SvgFilterDefinitions from '../svgFilterDefinitions/SvgFilterDefinitions';
import PageLayout from './PageLayout';
import LoggingInPlaceHolder from '../loggingInPlaceHolder/LoggingInPlaceHolder';
import NetworkStatus from '../networkStatus/NetworkStatus';
import ActivateMembershipDialog from '../activateMembershipDialog/ActivateMembershipDialog';
import {
  assignmentsPath,
  projectsPath,
  groupsPath,
  briefsPath,
  myContentPath,
  libraryPath,
  tutorialsPath,
  coursesPath,
  onboardingPath,
  submissionsPath,
  schoolsPath,
  dashboardPath,
  deviceSetupPath,
  deviceSetupWithUsbCablePath,
  deviceSetupWithUsbDrivePath,
  deviceSetupWithApPath,
} from '../../strings';
import { trackPageView } from '../../utils/track';
import { useTrackUser } from '../../hooks/useTrackUser';
import PrivacyPolicy from '../../pages/privacyPolicy/PrivacyPolicy';
import { UserType } from '../../types/graphql-types';
import { useAuth } from '../../context/Auth';

const ChallengesContainer = lazy(() =>
  import('../../pages/challenges/ChallengesContainer')
);
const ChallengePage = lazy(() => import('../../pages/challenge/ChallengePage'));
const SubmissionPageContainer = lazy(() =>
  import('../../pages/submission/SubmissionPageContainer')
);
const CoursePageContainer = lazy(() =>
  import('../../pages/course/CoursePageContainer')
);
const ExploreContainer = lazy(() =>
  import('../../pages/explore/ExploreContainer')
);
const TutorialsContainer = lazy(() =>
  import('../../pages/tutorials/TutorialsContainer')
);
const TutorialPageContainer = lazy(() =>
  import('../../pages/tutorial/TutorialContainer')
);
const OnboardingPageContainer = lazy(() =>
  import('../../pages/onboarding/Onboarding')
);
const HardwareOnboardingPageContainer = lazy(() =>
  import('../../pages/hardwareOnboarding/HardwareOnboarding')
);
const MakerbotActivate = lazy(() =>
  import('../../pages/activate/MakerbotActivate')
);
const Activate = lazy(() => import('../../pages/activate/Activate'));
const PitopActivate = lazy(() => import('../../pages/activate/PitopActivate'));
const DashboardPage = lazy(() => import('../../pages/dashboard/DashboardPage'));
const ClassPage = lazy(() => import('../../pages/class/ClassPage'));
const LegacyClassPage = lazy(() =>
  import('../../pages/legacyClass/LegacyClassPage')
);
const SchoolPage = lazy(() => import('../../pages/school/SchoolPage'));
// const UpgradeSchool = lazy(() =>
//   import('../../pages/upgradeSchool/UpgradeSchool')
// );

const NavigateToLibrary = () => {
  const { realmName } = useParams();
  const location = useLocation();

  if (!realmName) {
    return (
      <Navigate replace to={{ ...location, pathname: `/${libraryPath}` }} />
    );
  }

  return (
    <Navigate
      replace
      to={{ ...location, pathname: `/${libraryPath}/${realmName}` }}
    />
  );
};
const DeviceSetupChoicePage = lazy(() =>
  import('../../pages/deviceSetup/DeviceSetupChoicePage')
);
const UsbDriveSetupPage = lazy(() =>
  import('../../pages/deviceSetup/UsbDriveSetupPage')
);
const UsbCableSetupPage = lazy(() =>
  import('../../pages/deviceSetup/UsbCableSetupPage')
);
const ApSetupPage = lazy(() => import('../../pages/deviceSetup/ApSetupPage'));

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const searchParams = new URLSearchParams(location.search);
  const { myAccount, isReady } = useAuth();

  // When coming from the OS web portal, we need to remove the serial_number and device URL params
  if (searchParams.get('serial_number')) {
    searchParams.delete('serial_number');
    searchParams.delete('device');
    navigate({ search: searchParams.toString() }, { replace: true });
  }

  const firstLoadAfterLogin = searchParams.get('justLoggedIn') === 'true';

  if (location.state && (location.state as any).previousPath) {
    navigate((location.state as any).previousPath);
  }

  useTrackUser();

  useEffect(() => {
    trackPageView(pathname);
  }, [pathname]);

  if (firstLoadAfterLogin && !isReady) {
    return <LoggingInPlaceHolder />;
  }

  const isActivatingCode =
    searchParams.get('membership_code') || searchParams.get('activation_code');

  const noRedirectPaths = [
    '/activate',
    `/${briefsPath}`,
    `/${projectsPath}`,
    `/${coursesPath}`,
    `/${deviceSetupPath}`,
  ];
  if (
    firstLoadAfterLogin &&
    !isActivatingCode &&
    ![...noRedirectPaths, `/${dashboardPath}`].some((p) =>
      pathname.includes(p)
    ) &&
    [UserType.TEACHER, UserType.STUDENT].includes(
      myAccount?.userType || ('' as UserType)
    )
  ) {
    return (
      <Navigate
        to={{
          ...location,
          pathname: `/${dashboardPath}`,
        }}
      />
    );
  }

  if (
    firstLoadAfterLogin &&
    !isActivatingCode &&
    ![...noRedirectPaths, `/${onboardingPath}`].some((p) =>
      pathname.includes(p)
    ) &&
    myAccount?.userType === null
  ) {
    return <Navigate to={`/${onboardingPath}`} />;
  }

  return (
    <>
      <Meta />
      <SvgFilterDefinitions />
      <Routes>
        <Route element={<PageLayout />}>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* redirect old explore urls to /library */}
          {['/explore', '/explore/:realmName'].map((path) => (
            <Route key={path} path={path} element={<NavigateToLibrary />} />
          ))}

          {[`/${libraryPath}`, `/${libraryPath}/:realmName`].map((path) => (
            <Route key={path} path={path} element={<ExploreContainer />} />
          ))}

          {/* redirect old Challenges urls to /my-content */}
          {['/create', `/${briefsPath}`, `/${projectsPath}`].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <Navigate
                  replace
                  to={{ ...location, pathname: `/${myContentPath}` }}
                />
              }
            />
          ))}

          <Route path={`/${myContentPath}`} element={<ChallengesContainer />} />

          <Route
            path={`/${groupsPath}/:classId`}
            element={<LegacyClassPage />}
          />

          {[
            `/${briefsPath}/:id`,
            `/${projectsPath}/:id`,
            `/${briefsPath}/:id/edit`,
            `/${projectsPath}/:id/edit`,
            `/${briefsPath}/:id/preview`,
            `/${projectsPath}/:id/preview`,
            `/${coursesPath}/:courseId/${briefsPath}/:id`,
            `/${coursesPath}/:courseId/${projectsPath}/:id`,
            `/${coursesPath}/:courseId/${briefsPath}/:id/edit`,
            `/${coursesPath}/:courseId/${projectsPath}/:id/edit`,
            `/${coursesPath}/:courseId/${briefsPath}/:id/preview`,
            `/${coursesPath}/:courseId/${projectsPath}/:id/preview`,
            `/${groupsPath}/:classId/${briefsPath}/:id`,
            `/${groupsPath}/:classId/${projectsPath}/:id`,
            `/${groupsPath}/:classId/${briefsPath}/:id/edit`,
            `/${groupsPath}/:classId/${projectsPath}/:id/edit`,
            `/${groupsPath}/:classId/${briefsPath}/:id/preview`,
            `/${groupsPath}/:classId/${projectsPath}/:id/preview`,
            `/${groupsPath}/:classId/${briefsPath}/:id/grade`,
            `/${groupsPath}/:classId/${projectsPath}/:id/grade`,
          ].map((path) => (
            <Route key={path} path={path} element={<ChallengePage />} />
          ))}

          {[
            `/${submissionsPath}/:id`,
            `/${submissionsPath}/:id/edit`,
            `/${coursesPath}/:courseId/${submissionsPath}/:id`,
            `/${coursesPath}/:courseId/${submissionsPath}/:id/edit`,
            `/${groupsPath}/:classId/${submissionsPath}/:id`,
            `/${groupsPath}/:classId/${submissionsPath}/:id/edit`,
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={<SubmissionPageContainer />}
            />
          ))}

          <Route
            path={`/${coursesPath}/:id/edit`}
            element={<CoursePageContainer />}
          />
          <Route
            path={`/${coursesPath}/:id`}
            element={<CoursePageContainer />}
          />
          <Route
            path={`/${schoolsPath}/:schoolId/${groupsPath}/:classId`}
            element={<ClassPage />}
          />
          <Route path={`/${schoolsPath}/:schoolId`} element={<SchoolPage />} />
          <Route
            path={`/${tutorialsPath}/:id/slide/:slideNumber`}
            element={<TutorialPageContainer />}
          />
          <Route path={`/${tutorialsPath}`} element={<TutorialsContainer />} />
          <Route
            path={`/${onboardingPath}`}
            element={<OnboardingPageContainer />}
          />
          <Route
            path={`/${onboardingPath}/hardware`}
            element={<HardwareOnboardingPageContainer />}
          />

          {['/activate/makerbot', '/activateSchool/makerbot'].map((path) => (
            <Route key={path} path={path} element={<MakerbotActivate />} />
          ))}
          {['/activate/pi-top', '/activateSchool/pi-top'].map((path) => (
            <Route key={path} path={path} element={<PitopActivate />} />
          ))}
          {['/activate', '/activateSchool'].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <Activate
                  welcomeMessage="Activate Further License"
                  successMessage="You license has been activated successfully!"
                />
              }
            />
          ))}

          {/* assignment page has been removed, redirect to library */}
          <Route
            path={`/${assignmentsPath}/:id`}
            element={
              <Navigate to={{ ...location, pathname: `/${libraryPath}` }} />
            }
          />
          <Route path={`/${dashboardPath}`} element={<DashboardPage />} />
          <Route
            path={`/${deviceSetupPath}`}
            element={<DeviceSetupChoicePage />}
          />
          <Route
            path={`/${deviceSetupWithUsbCablePath}`}
            element={<UsbCableSetupPage />}
          />
          <Route
            path={`/${deviceSetupWithUsbDrivePath}`}
            element={<UsbDriveSetupPage />}
          />
          <Route path={`/${deviceSetupWithApPath}`} element={<ApSetupPage />} />
          <Route
            path="*"
            element={
              <Navigate
                replace
                to={{ ...location, pathname: `/${libraryPath}` }}
              />
            }
          />
        </Route>
      </Routes>
      <NetworkStatus />
      <ActivateMembershipDialog />
    </>
  );
};

export default memo(App);
