import React from 'react';
import { LinkType } from './utils/furtherLink';
import { COURSE_TYPE, PROJECT_TYPE } from './types/graphql-types';

// The default meta title
const metaTitle = 'Further';

// product names
const foundationKit = 'Electronics Kit';
const roboticsKit = 'Robotics Kit';

// Meta title for start brief page
const startBriefMetaTitle = 'Start Challenge';

// Meta title for start project page
const startProjectMetaTitle = 'Start Project';

// Meta title for create course page
const createCourseMetaTitle = 'Create Course';

// Meta title for create assessment page
const createAssessmentMetaTitle = 'Create Assessment';

// button text for creating new courses and challenges
const newCourse = 'New Course';
const newChallenge = 'New Challenge';
const newAssessment = 'New Assessment';

// Meta title for start space page
const createSpaceMetaTitle = 'Create Class';
const createSpacePlaceholder = "Click 'Plus' to create your first class";
const joinSpacePlaceholder = "Enter 'Join Code' to join your first class";

// Meta title for login page
const loginMetaTitle = 'Login';

// Meta title for sign up page
const signUpMetaTitle = 'Sign Up';

// Meta title for forgot password page
const forgotPasswordMetaTitle = 'Forgot Password';

// Success message on forgot password page
const forgotPasswordEmailSent =
  'Password reset email has been sent successfully.';

// Error message on forgot password page (unknown error)
const forgotPasswordError =
  'We were unable to send the password reset email, please try again.';

// Error message on forgot password page (email not found)
const forgotPasswordEmailNotFoundError =
  'We could not find an account associated with the email provided, please check the email and try again.';

// Meta title for reset password page
const resetPasswordMetaTitle = 'Reset Password';

// Success message on reset password page
const resetPasswordSuccess = 'Your password has been changed';

// Error message on reset password page when passwords do not match
const resetPasswordsDontMatch = 'Passwords do not match';

// Error message on reset password invalid token
const resetPasswordsInvalidToken = 'This link is not valid';

// Meta title for edit brief page
const editBriefMetaTitle = (title) => `Edit ${title}`;

// Meta title for edit project page
const projectMetaTitle = (title) => `${title}`;

// Meta title for edit space page
const editSpaceMetaTitle = (title) => `Edit ${title}`;

// Meta title for edit submission page
const submissionMetaTitle = (title) => `${title}`;

// Meta title for assignment page
const assignmentMetaTitle = (title) => `Assignment - ${title}`;

// Meta title for course page
const courseMetaTitle = (title) => `${title}`;

// Greeting for returning users in the navbar
const navbarGreeting = (name) => `Hello, ${name}`;

// Navbar buttons text
const navbarSignOutButton = 'Sign Out';

// Greeting for returning users above dashboard search
const dashboardGreetingGuest = 'Welcome :)';

// Greeting for returning users above dashboard search
const dashboardGreetingMember = (name) =>
  `Hey, ${name}… Nice to have you back :)`;

// Join space box
const joinSpaceInstruction = 'Class Join Code';

// Loading message for placeholders
const placeholderLoading = (item) => `Fetching ${item}...`;

// Error message for placeholders
const placeholderError = (item) => `Failed to fetch ${item}`;

const placeholderCreating = (item) => `Creating ${item}...`;

const placeholderCreateError = (item) => `Failed to create ${item}`;

const placeholderAddError = (item) => `Failed to add ${item}`;

// Explanation on add collaborator dialog
const addSubmissionCollaboratorDescription = 'Add a submission collaborator';
const addSubmissionCollaboratorExplanation = (title) => `\
Use the options below to add submission collaborators to ${title}.
Type the username of the person you wish to add`;

// Explanation on add editor dialog
const addCourseEditor = 'Add A Course Editor';
const addChallengeEditor = 'Add A Challenge Editor';
const addEditorExplanation = (title) => `\
Use the options below to add editors to ${title}.
Type the username of the person you wish to add`;

// Default error message if no custom message used
const defaultErrorMessage = 'Sorry! something went wrong...';

// Message in error dialog
const errorDialogMessage = (error) =>
  `There was a problem ${error}. Please try again.`;

// Message in error dialog when file is too large
const fileTooLargeErrorMessage =
  'Your file could not be uploaded as it exceeds 100 MB limit.';

// Message in error dialog when trying to upload a file but no file provided
const noFileProvidedErrorMessage = 'You have not provided any file to upload.';

// Explanation at the top of the brief builder
const briefBuilderTitle = 'Challenge Builder';
const briefBuilderExplanation =
  'Use the challenge builder below to add prompts explaining what needs to be done for this challenge';

// Error message when the youtube id supplied as an invalid format
const youtubeInputLabel = 'YouTube Id or URL:';
const youtubeIdErrorMessage = 'Invalid YouTube ID.';

// Error message when the wistia id supplied as an invalid format
const wistiaInputLabel = 'Wistia Id or URL:';
const wistiaIdErrorMessage = 'Invalid Wistia ID.';

// Error message when you try to upload an image without entering a URL'
const noImageUploadUrlError = 'No url passed to uploadImage.';

// Error message when you try to upload an unsupported file type
const unsupportedFileTypeError = 'File type is not supported.';

// Error message when image upload fails
const imageUploadErrorMessage =
  'The image provided cannot be uploaded, please check the filetype and url';

// Calculate supported extensions message: Supported file extensions: jpg, gif, etc
const supportedExtensionsListMessage = (supportedExtensions) =>
  supportedExtensions &&
  `Supported file extensions: ${supportedExtensions
    .slice(0, supportedExtensions.length - 2)
    .reduce((acc, ext) => `${acc}${ext}, `, ' ')}${supportedExtensions
    .slice(-2)
    .join(' and ')}`;
// Error shown when trying to create a duplicate reader group
const existingReaderGroupError = 'Version is already published to that space';

// Error when trying to publish without selecting version and space
const readerGroupError = 'Please select the version & space to publish';

// Explanation shown when trying to publish without a locked version
const noLockedVersions = 'You must lock a version to publish';

const removeItemTitle = 'Are you sure?';
const remvoeChallengeFromCourse =
  'This will remove the Challenge from the Course';
// Default warning message when deleting an item
const removeItemWarning = (itemName = 'item') =>
  `This will permanently delete this ${itemName}`;
const removeItemPostfix = 'and any content inside it';

// Warning message shown when trying to download a file
const downloadFileWarning =
  'Downloading and opening unknown files can compromise your computer security';

// Warning message shown when trying to delete a file
const deleteFileWarning = 'This will permanently delete this file.';

// This message will appear when FEATURE_FLAG_SIGNUP is 'disabled'
const featureFlagSignUpNote =
  'Registration to Further is not currently available';

// Name and pathname section for Explore
const explorePageName = 'Explore';
const libraryPath = 'library';

// Name, pural, and pathname section for the Project (not Worksheet) Model
const challengeModelName = 'Challenge';
const challengeModelNamePlural = 'Challenges';
const projectsPath = 'projects';
const newUrlParam = 'new';
const emptyChallengesMemberPlaceholder =
  'Your class does not have any assignments yet.';
const emptyChallengesAdminPlaceholder =
  'You have not added any Challenges yet. Use one of the Further challenges or create your own to share with your class';
const draftHasChanges = 'Draft: unpublished changes';

// pathname section for the Assessment Model
const assessmentModelName = 'Assessment';
const assessmentModelNamePlural = 'Assessments';

// pathname section for the Challenge Model
const briefsPath = 'challenges';
const myContentPath = 'my-content';

// editor only 'codebox' path
const codeboxPath = 'codebox';

// Name, pural, and pathname section for the Group (aka Space) Model
const groupModelName = 'Class';
const groupModelNamePlural = 'Classes';
const groupsPath = 'classes';
const deleteGroupWarning = 'This will permanently delete the Class';

// Name, pural, and pathname section for the Group (aka Space) Model
const tagModelName = 'Tag';
const tagModelNamePlural = 'Tags';
const addTagsDescription = 'Choose the tags you want to add';
const allTagsAddedDescription = 'All tags have been added';
const removeTagDescription = (tag) => `Tag '${tag}' will be removed`;
const errorRemovingTag = errorDialogMessage('removing your tag');
const emptyTagsTab = 'No tags added yet';

const deviceModelName = 'Product';
const deviceModelNamePlural = 'Products';
const addDevicesDescription = 'Choose the products you want to add';
const allDevicesAddedDescription = 'All products have been added';
const removeDeviceDescription = (device) =>
  `Product '${device}' will be removed`;
const errorRemovingDevice = errorDialogMessage('removing your product');
const emptyDevicesTab = 'No products added yet';
const removeProductTitle = 'Remove Product';
const addProductTitle = 'Add Products';

const realmModelName = 'Realm';
const realmModelNamePlural = 'Realms';
const addRealmsDescription = 'Choose the realms you want to add';
const allRealmsAddedDescription = 'All realms have been added';
const removeRealmDescription = (realm) => `Realm '${realm}' will be removed`;
const errorRemovingRealm = errorDialogMessage('removing your realm');
const emptyRealmsTab = 'No realms added yet';

const schoolModelName = 'School';
const schoolModelNamePlural = 'Schools';
const schoolsPath = 'schools';

const schoolMemberModelName = 'School Member';
const schoolMemberModelNamePlural = 'School Members';
const addSchoolMembersDescription =
  'Add school members to list by email, username or name and then click Confirm';

// submission collaborator name
const submissionCollaboratorName = 'Submission Collaborator';
const submissionCollaboratorNamePlural = 'Submission Collaborators';
const submissionCollaborateName = 'Collaborate';

// Project editor name
const challengeEditorName = 'Challenge Editor';
const challengeEditorNamePlural = 'Challenge Editors';
const challengeEditName = 'Edit';

// Course editor name
const courseEditorName = 'Course Editor';
const courseEditorNamePlural = 'Course Editors';
const courseEditName = 'Edit';

// name, plural, and pathname for the Course Model
const courseModelName = 'Course';
const courseModelNamePlural = 'Courses';
const coursesPath = 'courses';

// Next, previous buttons
const navigationPreviousLink = 'Previous';
const navigationNextLink = 'Next';

// Name, pural, and pathname section for the Project (not Worksheet) Model
const assignmentModelName = 'Assignment';
const assignmentModelNamePlural = 'Assignments';
const assignmentsPath = 'assignments';

// Name, pural, and pathname section for the Submission Model
const submissionModelName = 'Submission';
const submissionModelNamePlural = 'Submissions';
const submissionsPath = 'submissions';

const inviteModelName = 'Membership';

// Name, pural, and pathname section for the Tutorials Model
const tutorialsPageName = 'Tutorials';
const tutorialsPath = 'tutorials';

const onboardingPageName = 'Onboarding';
const onboardingPath = 'onboarding';

const dashboardPath = 'dashboard';

const hardwareOnboardingQuestionsNone = "I don't have any of the above";

// warning shown when removing a project/brief collaborator
const removeCollaboratorText = (user, thing) => `\
${user} will no longer be able to collaborate on ${thing}`;

// warning shown when removing a space member or admin
const removeMemberText = (name) =>
  `${name} will no longer have access to this class`;

// text shown when removing a collaborator / member
const removingText = (name) => `removing ${name}`;

// version name
const versionName = 'Version';
const versionNamePlural = 'Versions';
const newVersionPrompt = 'Lock this version';

// Group member name
const memberName = 'Student';
const memberNamePlural = 'Students';

// Group admin name
const adminName = 'Teacher';
const adminNamePlural = 'Teachers';

// project title name
const projectTitleName = 'Title';

// brief title name
const briefTitleName = 'Title';

// Group title name
const groupTitleName = 'Name';

// Referring to the current user
const youName = 'You';

// Preview link title
const previewLinkTitle = 'Preview';

// View link
const view = 'View';
// Delete button
const deleteButtonText = 'Delete';

// Explanation on add member dialog
const addMemberDescription = 'Add students';
const addMemberExplanations = [
  'Use the options below to add students & teachers to the class',
  'Type the username of the person you wish to add',
];
const addMemberByName = 'Invite with username';
const addMemberByNamePlaceholder = 'Type/Copy Username';
const addMembersByCode = 'Invite with code';
const addMembersByCsv = 'Add from CSV file';
const uploadMembersCsv = 'Click or drag and drop to add .csv file';
const studentOnlyLabel = '(students only)';

// name for adding readerGroups to a project/brief
const publishName = 'Publish';
const publishPresentParticiple = 'Publishing';

// Comments
const commentName = 'Comment';
const commentNamePlural = 'Comments';
const newCommentPrompt = 'Add a new comment';
const reportCommentWarningText = `
Thank you for bringing this to our attention. While this comment is being reviewed, it will continue to be seen on Further.
If, upon review, we find that the comment goes against our policies, then we will take it down.
Please continue to report comments that you think don't belong on our projects and are offensive or inappropriate.
`;

// Report
const reportButtonTitle = (kind) => `Report ${kind}`;
const reportDialogTitle = (kind) => `Report ${kind}`;
const reportSubmitButton = 'Report';

const reportTitle = (blockType) => `Is there a problem with this ${blockType}?`;
const reportWarningText = `
Thank you for bringing this to our attention. Please provide an explanation of what is wrong below and we will try to improve it as soon as possible. Please continue to report content that you think could be improved.
`;
const reportSubmittedTitle = 'Thanks for your feedback';
const reportSubmittedWarningText = (kind) =>
  `Thanks for reporting an issue with the ${kind}, we will look into it`;

// Delete Block
const deleteBlockButtonTitle = 'Delete Block';
const deleteBlockDialogTitle = 'Are you sure?';
const deleteBlockDialogSubmitButton = 'Delete';

// sections
const sectionName = 'Section';
const sectionNamePlural = 'Sections';
const clickToAddNewSection = 'Click to add New Section';
const clickToImportSection = 'Click to import a section from another challenge';

// Import section
const importSectionButtonText = 'Import section';
const importSectionErrorMessage =
  'We could not import the section, please try again';
// tasks
const taskName = 'Task';
const taskNamePlural = 'Tasks';

// tasks responses
const taskResponseName = 'Task Response';
const taskResponseNamePlural = 'Task Responses';

const mockTaskResponseLoginPrompt =
  'You must log in to use interactive features!';

const exploreAddToClassLoginPrompt =
  'To start making assignments please log in or sign up as a teacher.';

// Delete Task
const deleteTaskButtonTitle = 'Delete Task';
const deleteTaskDialogTitle = 'Are you sure?';
const deleteTaskDialogSubmitButton = 'Delete';

// blocks
const blockModelName = 'Block';
const blockModelNamePlural = 'Blocks';

// This help message will appear below the input title field of Project/Brief/Space',
const searchTagHelp =
  'Anything after a "#" symbol will be hidden and only used for search purposes.';

// nav login label
const logInLabel = 'Log In';

// nav sign up label
const signUpLabel = 'Sign Up';

// register interest form
const interestFormDesc =
  'Sign up to register your interest for the full version of';
const interestFormTitle = 'Further your';
const alreadyHaveAccount = 'Already have an';

// publish project dialog
const shareProjectButton = 'Share to Spaces';
const shareProjectDialogTitle = 'Share to Spaces';
const shareProjectDialogSearchLabel = 'Choose or Search for Spaces';
const shareProjectDialogWillBePublicMessage =
  'Sharing with public Spaces will mean the Project is visible to everyone';
const shareProjectDialogWontBePublicMessage =
  'Sharing without public Spaces will mean the Project is no longer visible to everyone';
const sharingErrorMessage = 'There was a problem sharing, please try again';
const projectIsPublicMessage = 'Project is public';
const addProjectToPublicCourseWarningMessage =
  'Adding this Challenge to a public Course will make the Challenge visible to everyone';
// add to class dialog
const assignDialogTitle = 'Assign to Class';
const assignDialogSearchLabel = (label) =>
  `Select the class you want to add ${label} to`;
const assignDialogLoadingMessage = 'Fetching classes...';
const assignDialogNoClassesMessage = 'No Classes Found';
const assignDialogCheckGroupName = 'classes';
const assignDialogConfirmActionLabel = 'Add';
const assignErrorMessage = 'We were unable to add to class, please try again';

// add course challenge dialog
const addCourseChallengeButton = 'Add to Course';
const addCourseChallengeDialogTitle = 'Choose a Course';
const addCourseChallengeDialogLoadingMessage = 'Fetching courses...';
const addCourseChallengeDialogSearchLabel =
  'Select the course you want to add challenge to';
const addCourseChallengeDialogNoCoursesMessage =
  'You don’t have any courses. Go to the Challenges tab to create your first course.';
const addCourseChallengeDialogCheckGroupName = 'courses';
const addCourseChallengeDialogConfirmActionLabel = 'Add';
const addCourseChallengeErrorMessage =
  'We were unable to add to course, please try again';

// add submission dialog
const addSubmissionDialogTitle = 'How are you working on this Challenge?';
const addSubmissionDialogSearchLabel = 'Search for collaborators';
const addSubmissionErrorMessage =
  'We were unable to create submission, please try again';
const addSubmissionDialogConfirmLabel = 'Start';
const submissionsNamePlural = 'Submissions';

// when registering or accepting an updated privacy policy
const privacyPolicyRequest = 'Accept our privacy policy to continue';

// This help message will appear as placeholder in search bar
const searchPlaceholder = 'What would you like to find?';

// Standards
const standardName = 'Standard';
const standardNamePlural = 'Standards';
const removeStandardText = (standard) =>
  `Standard '${standard}' will be removed from this project`;

const emptyStandardsTab = 'No standards added yet';
const emptyStandardsList = 'No standards found';
const emptyMultiSelectList = 'No items added';

// Teacher Resources
const teacherResource = 'Teacher Resource';
const teacherResourcePlural = 'Teacher Resources';
const emptyTeacherResourcesTab = 'No teacher resources added yet';
const areYourSureResources = (name) =>
  `Are you sure you want to move the resource "${name}" ?`;
const removeTeacherResource = 'removing your resource';

// No Record message for placeholders
const placeholderNoRecord = (item) => `There are no ${item}`;

const consoleLabel = 'Console';
const filesLabel = 'Files';
const videoLabel = 'Video';
const novncLabel = 'Display';
const htmlLabel = 'HTML Preview';

// this help message will appear on the add url dialogue box
const urlHelpText = 'Paste or type a URL here';
const createNewLink = 'Create New Link';

// aria link help text for links that open in a new window
const linkAriaLabel = 'This is an external link (opens in a new tab)';

const projectUpdateHelpText =
  'You are editing a draft version with unpublished changes. Click to make the changes visible to readers';

const membershipLoginMessage =
  'Please log in or sign up first to activate your membership code';
const dontHaveInviteCodeText = `Don’t have an membership code? Start your `;
const orStartYour = 'Or start your ';
const startYour = 'Start your';
const hasAMembershipCode = 'Have a membership code?';
const cancelMembership = 'Cancel membership';
const membershipActivated = 'Your Further membership has been activated!';
const membershipCancelled = 'Your Further membership has been deactivated!';

const salesEmail = `sales@pi-top.com`;
const paywallPlaceholderLAUSD = `If you are an LAUSD teacher, please contact ${salesEmail} to redeem your license.`;
const paywallPlaceholder = `For purchase options please contact ${salesEmail}`;

const moreInformation = `Don't have a membership code? To request access, please contact`;
const membershipCodeErrorMessage = `We could not verify your membership code, please try again`;
const cancelMembershipErrorMessage = `Sorry, there was a problem cancelling your membership`;
const membershipCodeLabel = `Membership Code`;
const accessClassTabs = [`Teachers`, `Students`];
const activateMembershipTitle = 'Activate Membership';
const noneMembershipStatusTitle = 'No Membership';
const fullMembershipStatusTitle = 'Full Membership';
const trialMembershipStatusTitle = 'Trial Membership';
const expiredMembershipStatusTitle = 'Your membership has expired...';
const activateMembershipHintText =
  'To get access to classroom features please enter a membership code below';
const toContinueUsingAllTheFeaturesHintText =
  'To continue using all the features please enter a memberhip code below';
const getRemainingMembershipTitlePart = (until) => ` (${until} remaining)`;
const getExpiredAgoMembershipTitlePart = (until) => ` (${until} ago)`;

const activatePath = 'activate';
const activateLicenseTitle = `Activate License`;
const activateLicenseHint = `Enter a License Code below to activate it on your account`;
const activateLicenseInputLabel = `License Code`;
const activateLicenseSubmit = 'Activate';
const licenseActivated = 'Your License has been activated!';
const licenseCodeErrorMessage = `We could not verify your License Code, please try again`;
const activatedContentLicenseDescription = `You now have access to the following premium content:`;
const activatedContentLicenseShareInfo = (shares) =>
  ` - share with up to ${shares} students`;
const activatedContentShareExplanation = `You can share access to this content with your students from the`;
const activatedContentShareExplanationPage = `students management page`;

const skip = 'Skip';
const youCanDoThisLater = 'You can do this later';
const youMustLoginThroughGoogleClassroomOrClever =
  'You must login through Google Classroom or Clever to use this feature';

const external = 'External';
const externalClassEditingLockedMessage =
  "This class has been 'Synced'. To make changes click ";

const blockName = (blockType) => {
  switch (blockType) {
    case 'iframe':
      return 'webpage';
    default:
      return blockType;
  }
};

const uploadVideoInstructions = 'Click to add video or drag and drop';
const uploadImageInstructions = 'Click to add image or drag and drop';
const uploadFileErrorMessage = 'uploading your file';
const uploadFileInstructions = 'Click to add file or drag and drop';

const notificationsEmpty = 'You have no new notifications';
const notificationMessages = {
  ADD_COMMENT_ON_PROJECT: ({ count }) =>
    count > 1
      ? `added ${count} comments to your challenge`
      : `added a comment to your challenge`,
  ADD_COMMENT_ON_SUBMISSION: ({ count }) =>
    count > 1
      ? `added ${count} comments to your submission`
      : `added a comment to your submission`,
  ADD_COMMENT_REPLY: ({ count }) =>
    count > 1
      ? `added ${count} replies to your comment on`
      : `added a new reply to your comment on`,
  EDIT_PROJECT: () => 'made changes to your challenge',
  EDIT_SUBMISSION: () => 'made changes to your submission',
  ADD_ASSIGNMENT_ON_CLASS: ({ count }) =>
    count > 1
      ? `assigned ${count} challenges to your class`
      : `assigned a new challenge to your class`,
  ADD_SUBMISSION_ON_ASSIGNMENT: () =>
    'started a new submission on the assignment',
  ADD_GROUP_MEMBER: () => 'added you to the class',
  ADD_GROUP_ADMIN: () => 'made you a teacher of the class',
};

const tutorialConnectionErrorHTML =
  "It looks like there was a problem connecting to your pi-top, please try again or follow the instructions <a target='pop' href='https://knowledgebase.pi-top.com/knowledge/further-code-runner-connection'>HERE</a>";

const codeErrorMessage =
  'That code will not run! Look in the output below the code to see the number of the line that has an error and see if you can fix it';
const tutorialCodeResetText = 'reset the code';
const pressConnectToGetStarted = (
  <>
    Click the <strong>pi-top</strong> icon and then <strong>Connect</strong> to
    get started
  </>
);

const idTechRegistrationUrl = 'https://iDTechOnline.as.me/pitop4';
const tutoringDialogTitle = 'One to one introduction session with pi-top';
const tutoringIntro = `To help you get started with your pi-top, you can select one of the challenges below and book a free 1:1 introductory session with one of our tutors. After that, you'll be ready to tackle all the other great challenges on Further, our learning platform.`;
const tutoringTechChallenges = [
  'Tackle food waste with APIs',
  'Give me good news',
  'Hand Wash Timer',
];
const tutoringDialogDescription =
  'Great, someone from the pi-top team will get back to you soon...';
const tutoringFormSubmissionDescription =
  'Please fill in the form below and we will get back you...';
const idTechBookingInstruction = `We'll now redirect you to the iD Tech registration page to book your session. Please note, to do the session you'll need either a mobile phone or laptop with camera and microphone`;
const membershipCodeUrlParamName = 'membership_code';
const classesListUpgradeAccount =
  'You may only create one class on your current membership';
const updagradeMembership = 'Upgrade your membership?';
const backLabel = 'Back';
const furtherLinkRunError =
  'There was a problem communicating with your device. Please reconnect and retry.';
const furtherLinkConnectError = ({ linkType, ipAddress }) => {
  let response = 'Could not connect to your pi-top; ';
  if (linkType !== LinkType.Bluetooth) {
    response += 'your device IP address may have changed or ';
  }
  response += 'it may require a ';
  response += ipAddress
    ? `<a style="font-weight: bold; color: #00ad9f;" target='pop' href='http://${ipAddress}/updater'>software update</a>. `
    : 'software update. ';

  const kbArticleUrl =
    linkType === LinkType.Bluetooth
      ? 'https://knowledgebase.pi-top.com/knowledge/connect-to-further-using-bluetooth'
      : 'https://knowledgebase.pi-top.com/knowledge/further-code-runner-connection';
  response += `For more information check the <a style="font-weight: bold; color: #00ad9f;" target='pop' href='${kbArticleUrl}'>connection instructions</a>.`;

  return response;
};
const furtherLinkBluetoothConnectError =
  "There was an issue when connecting to the device. Please refresh this page and try again. If you continue having issues, read this <a style='font-weight: bold; color: #00ad9f;' target='pop' href='https://knowledgebase.pi-top.com/knowledge/connect-to-further-using-bluetooth'>Knowledge Base article</a>.";
const furtherLinkNoBluetoothAdapterError =
  "Couldn't find a Bluetooth adapter in your computer. Make sure you have one and verify it's enabled in your OS settings.";

const furtherLinkUploadError = 'There was a problem uploading your files.';

const furtherLinkName = 'Further Link';
const sdkName = 'Python SDK';
const systemSoftwareNotFound = (softwareName) =>
  `Your device does not have ${softwareName} installed, please update your device to avoid errors and unexpected behaviour.`;
const systemSoftwareBehindMajor = (softwareName) =>
  `Your device is using an old version of ${softwareName}. If you don't update your pi-top you will likely encounter errors and unexpected behaviour.`;
const systemSoftwareBehindMinor = (softwareName) =>
  `Your device is not using the latest version of ${softwareName}. Please update your pi-top device if you want to use the latest Further features.`;
const systemSoftwareBehindPatch = (softwareName) =>
  `Your device is not using the latest version of ${softwareName}. Please update your pi-top device to get the latest bug fixes and improvements.`;

const updateFurtherLinkTitle = 'Please update first';
const updateFurtherLinkCallToAction =
  'Click the button below to open the System Updater';
const updateFurtherLinkWaitForUpdate = (
  <>
    Thanks for opening the updater, please start the update and wait for it to
    finish, which may take around 10 minutes.
    <br />
    <br />
    We will let you know when we have detected that your pi-top is up to date.
  </>
);
const updateFurtherLinkMoreInformation = (
  <>
    More information about updating can be found in our{' '}
    <a
      target="pop"
      href="https://knowledgebase.pi-top.com/knowledge/further-code-runner-connection"
      style={{ fontWeight: 'bold', textDecoration: 'none' }}
    >
      knowledge base
    </a>
  </>
);
const updateFurtherLinkComplete =
  "Thank you for updating your pi-top! The latest software is installed and you're ready to continue.";

const addToClassLabel = 'Add to Class';
const addedToClassLabel = 'Added to Class';

const fourOhFourTitle = (resourceName = 'page') =>
  `Oops, we can't find this ${resourceName}...`;
const fourOhFourLoggedInMessage = (resourceName = 'page') =>
  `If you think this is a mistake please contact one of the ${resourceName} owners for access...`;
const fourOhFourGuestMessage = (resourceName = 'page', partIndex = 0) => {
  const parts = [
    'You may need to',
    `or contact one of the ${resourceName} owners for access...`,
  ];
  return parts[partIndex];
};

const errorTitle = 'Ah sorry, something went wrong...';
const errorRefreshMessage =
  'we might be already working to fix this but try refreshing the page';
const errorRefreshButtonTitle = 'Refresh';
const errorRetryMessage = (resourceName = 'page') =>
  `failed to fetch ${resourceName}`;
const errorRetryButtonTitle = 'Retry';
const errorFooter = (partIndex = 0) => {
  const parts = ['Or contact', 'Support', 'for help'];
  return parts[partIndex];
};

const clickGuardLabel = (guardedElementName) => `Locked ${guardedElementName}`;
const mockBlockLoginPrompt = 'You must log in to use interactive features!';

const redirectLabel = (place) => `Go to ${place}`;
const alreadyAssignedToClassTitle = 'Challenge already assigned to a class';
const alreadyAssignedToClassMessage =
  'We noticed that this challenge has already been assigned to you in one of your classes. Click the button bellow to go to the class';
const publishChangesButton = 'Publish Changes';

const defaultEditableTitlePlaceholder = 'Add a title';
const defaultEditableDescriptionPlaceholder = 'Add a description here...';

const sectionTitlePlaceholder = 'Add a title';
const sectionCompleteCheckboxLabel = 'Mark section as complete';
const sectionCompleteMessage = 'Student completed';

const addTaskButtonLabel = 'Add New Task';

const textTaskDetailPlaceholder = 'Write your answer here';

const connected = 'Connected';
const connect = 'Connect';
const connecting = 'Connecting...';
const disconnected = 'Disconnected';
const disconnect = 'Disconnect';
const connectingCancel = 'Cancel…';
const makeCopy = 'CLONE';

const connectDeviceDropdownLabel = 'Connect Device Dropdown';

const taskTypeMenuButtonText = 'Choose Student Response';
const taskTypeMenuDropdownLabel = 'Task type menu dropdown';

const webpageTaskDetailInputLabel = 'URL';
const webpageTaskDetailInputPlaceholder = 'https://pi-top.com';

const addOtherEditorsLabel = 'Can add other editors';
const addOtherCollaboratorsLabel = 'Can add other collaborators';

const cloneLoading = 'We are cloning this Challenge… bare with us';
const cloneSuccess = 'Your Challenge was cloned';
const cloneError = 'Oh no… there was an error cloning this Challenge';
const publishLoading = 'We are publishing this Challenge… bare with us';
const publishSuccess = 'Your Challenge was published';
const publishError = 'Oh no… there was an error publishing this Challenge';

const draftSubmissonAddMemberInstructionText = 'Go to edit mode to add editors';
const stopCodeToEditTooltipLabel = 'Stop the code to edit';

const askSignupTitle = 'Great start :)';
const askSignupDescription =
  'This is just the beginning of your learning journey with Further.<br /><br /> Please <strong>sign up</strong> or <strong>log in</strong> to save your progress<br /> and discover great Challenges to tackle with your pi-top devices.';
const newToFurtherLabel = 'New pi-top? Start Here!';

const errorMessages = {
  networkError:
    'There was a connection problem while saving. Please check your internet and try again',
  graphQLErrors:
    'There was a problem while saving. Please reload the page and try again',
  defaultErrorMessage:
    'There was a problem while saving. Please reload the page and try again',
};

const piTopThree = 'pi–top [3]';
const piTopFour = 'pi–top [4]';

const marketingBannerTitlePitop =
  'Inquiry-Based Learning that’s Relevant and Engaging!';
const marketingBannerTeacherBlurbPitop = (
  <p>
    Enhance your core classes or elevate your CS and CTE courses with{' '}
    <strong>interactive, standards-aligned</strong> lessons that seamlessly
    integrate STEAM and core subjects. Our content connects classroom learning
    to <strong>real-world topics</strong> and <strong>essential skills</strong>,
    sparking creativity, fostering problem-solving, and building the critical
    abilities students need for the future. With pi-top, educators are empowered
    to design dynamic learning experiences that not only{' '}
    <strong>engage students</strong> but also equip them to tackle tomorrow’s
    challenges.
  </p>
);
const marketingBannerStudentBlurbPitop = (
  <p>
    Through hands-on, inquiry-based lessons, you&apos;ll gain valuable{' '}
    <strong>life and job skills</strong> while exploring exciting topics that
    matter. Along the way, you&apos;ll discover potential careers,{' '}
    <strong>solve real problems</strong>, and see how what you learn connects to
    the world around you. With pi-top, learning is all about you —{' '}
    <strong>your interests</strong>, your future, and your success!
  </p>
);
const marketingBannerSignUpTextPitop = 'Sign Up as a Teacher';

const marketingBannerTitleMakerbot =
  '3D Printing for the next generation of Designers and Engineers.';
const marketingBannerTeacherBlurbMakerbot =
  'MakerBot supports educators with more than just software and hardware - becoming MakerBot certified is a critical step for educators to validate their 3D printing expertise and stand out as STEM education leaders. From setting your classroom or district up for success to adding to your own value and skills as an educator.';
const marketingBannerStudentBlurbMakerbot =
  'Leave the frustration of classroom technology barriers behind — only MakerBot 3D printers offer a full STEM classroom 3D printing ecosystem. With the MakerBot Certification™ Program for Students, students gain a proven edge in 3D printing, design thinking, and 21st-century career skills training.';
const marketingBannerPurchaseLicenseMakerbot = 'Get Details and Licenses';

const marketingBannerTitleMerge = 'Learn Science, Master STEM, Be Future Ready';
const marketingBannerBlurbMerge = `Merge engages students in science and STEM with 3D objects and simulations they can touch, hold and interact with.`;
const marketingBannerLearnMoreTextMerge = 'Learn More about Merge EDU';

const marketingBannerTitle3doodler = 'Classroom Learning Goes 3D';
const marketingBannerBlurb3doodler = `3Doodler is here to help you lift your lessons off of the page. Great for project based learning and CTE, 3Doodler pens will spark engagement and bring hands-on learning to your classroom.`;
const marketingBannerLearnMoreText3doodler = 'Learn More about 3Doodler EDU';

const marketingBannerTitlePLTW = 'Every Teacher Deserves a STEM Ally';
const marketingBannerBlurbPLTW = `Created by teachers and led by educators, PLTW motivates, prepares, and supports teachers as they strive to make every child in every grade STEM-successful. From the first day to the last day, PLTW is the STEM ally you and your teaching staff can count on.`;
const marketingBannerLearnMoreTextPLTW =
  'Learn More about Project Lead The Way';

const marketingBannerTitleDatabot =
  'The Future of Data Powered Learning. Transform Your STEAM Programs Today with databot™!';
const marketingBannerBlurbDatabot =
  'databot™ provides educators across multiple grade levels and disciplines with a common tool for visualizing and capturing real, scientific data that brings science, math, and technology activities to life.';
const marketingBannerLearnMoreTextDatabot = 'Learn More about databot™';

const marketingBannerTitleLausd = (
  <span>
    LAUSD CS For Good:
    <br />
    Design, STEM, and Robotics
  </span>
);
const marketingBannerBlurbLausd = '';
const marketingBannerLearnMoreTextLausd = 'Register for Access';

const notStarted = 'Not started';
const inProgress = 'In progress';
const done = 'Done';

const deleteAssignmentWarningText = (assignmentName) =>
  `This will remove the challenge "${assignmentName}" from the class`;
const deleteAssignmentErrorMsg =
  'There was a problem removing the challenge from the class';
const deleteAssignmentTitle = 'Remove challenge';
const confirm = 'Confirm';

const explainPublicVisibility = (type) =>
  `${type} with public visibility are searchable on the explore page`;
const explainShareableVisibility = (type) =>
  `${type} with shareable visibility are available to anyone with the link`;
const explainPrivateVisibility = (type) =>
  `${type} with private visibility will only be visible to editors`;

const visibilityOfCourseMsg = (visibility) =>
  `This challenge is in a ${visibility} course`;
const editChallengeVisibilityDialogTitle = 'Edit Challenge Visibility';
const editChallengeVisibilityDialogButtonText = 'Set visibility';
const editChallengeVisibilityDialogInstruction =
  'Change who can see this Challenge';
const editCourseVisibilityDialogTitle = 'Edit Course Visibility';
const editCourseVisibilityDialogInstruction = 'Change who can see this Course';
const editChallengeVisibilityDialogErrorMessage = (type) =>
  `There was a problem ${type} the challenge visibility data, please try again`;
const editVisibilityButton = 'Edit Visibility';
const editCourseVisibilityDialogErrorMessage =
  'There was a problem changing the course visibility, please try again';

const createCourseErrorMessage =
  'There was a problem creating your course, please try again';

const toggleAssignmentSubmissionsButtonText = 'Toggle assignment submissions';

const connectFlyoutToggleButtonAriaLabel = 'Device connection menu';

const pageModeDropdownLabel = 'Page mode dropdown';

const gradeFlyoutToggleAriaLabel = 'Assignment Feedback';
const gradeFlyoutLoading = 'Loading feedback...';
const gradeFlyoutError =
  'There was a problem loading feedback, plaese try again';
const gradeFlyoutSubmissionNotFound = 'No feedback found';

const manageAccountLink = 'Manage Account';

// complete submission button
const submitWorkLabel = 'Submit Work';
const submittedMessage = 'Submitted';
const notSubmittedMessage = 'Not Yet Submitted';
const loadingSubmitted = 'Loading submitted status...';
const errorLoadingSubmitted = 'Error loading submitted status';
const completeWorkLabel = "I'm Done";
const completedMessage = 'Completed';
const notCompletedMessage = 'Not Yet Completed';
const loadingCompleted = 'Loading completed status...';
const errorLoadingCompleted = 'Error loading completed status';

const makerbotActivateWelcome =
  'Welcome to MakerBot Certification with Further';
const pitopActivateWelcome = 'Welcome to pi-top Further';
const activateLoading = 'One moment...';
const activateLogin = 'Log in to activate your license';
const activateLoginDescription =
  "Once logged in you'll be redirected back here, or you can find the link again in your email.";
const activateChooseSchool = 'Choose a school to activate your license on';
const activateChooseSchoolDescription =
  'We link your license to a School so that you can share it with students and other teachers';
const activateSelectSchoolPlaceholder = 'Select school';
const activateCreateSchool = 'Create a school';
const activateEnterCode = 'Enter license code for: ';
const activateEnterCodeDescription =
  'It should be prefilled if you clicked the link in your email';

const activateSuccessTitle = 'License successfully activated!';
const upgradeSuccessTitle = 'License successfully upgraded!';
const activateUpgradeMessage =
  'Need more teacher and student seats? Upgrade now!';
const makerbotActivateSuccessDescription =
  'You now have access to the MakerBot Certification Program.';
const pitopActivateSuccessDescription =
  'You now have access to the pi-top Premium content.';
const activateAdditionalLicenses =
  'You can activate additional licenses from the school page.';
const activateGoToDashboard = 'Go to Teacher Dashboard';

const schoolLicensesMustBeAdmin =
  'You must be a school admin to manage licenses. You can still give access to premium content from the roster tab.';
const schoolActivateLicenseButtonLabel = 'Activate License Code';
const schoolManageLicensesButtonLabel = 'Manage Licenses';
const schoolLicensesSummaryTitle = 'Summary';
const schoolLicenseListTitle = 'Active Licenses';
const schoolNoLicensesMessage =
  'No licenses have been activated for this school. Activate a license code to start using the platform.';
const schoolLicensesListStatusColumn = 'Status';
const schoolLicensesListNameColumn = 'Name';
const schoolLicensesListDescriptionColumn = 'Description';
const schoolLicensesListCodeColumn = 'Code';
const schoolLicensesListExpiresColumn = 'End';
const schoolLicensesListRenewColumn = 'Renew';

const schoolActivateLicenseTitle = `Activate License`;
const schoolActivateLicenseHint = `Enter a License Code to activate it on your School`;
const schoolActivateLicenseInputLabel = `License Code`;
const schoolActivateLicenseSubmit = 'Activate';
const schoolActivateLicenseLoading = 'Activating...';

const schoolNoLicensesWarning = 'School is locked, add a license to unlock';
const schoolOverAllocatedWarning = 'School is locked, renew license to unlock';
const schoolUnpaidLicensesWarning =
  'License payment due, School will lock soon';
const missingContentAccessWarning = 'Member needs access to this content';

// device setup
const deviceSetupPath = 'setup-devices';
const deviceSetupWithUsbDrivePath = `${deviceSetupPath}/usb-drive`;
const deviceSetupWithUsbCablePath = `${deviceSetupPath}/usb-cable`;
const deviceSetupWithApPath = `${deviceSetupPath}/ap`;
const deviceSetupLoading = 'One moment...';
const deviceSetupLogin = (
  <>
    <h2>You must log in before you can continue.</h2>
    <p>Once you&apos;re done you will be redirected back here.</p>
  </>
);

const deviceSetupDownloadLabel = 'Download';
const continueLabel = 'Continue';
const downloadFinishedLabel = 'My Download finished';
const setupAnotherDeviceLabel = 'Setup another device';

const deviceSetupDownloadPrompt = (
  <>
    Lets <span style={{ color: '#00AD9F' }}>setup</span> your device!
  </>
);
const deviceSetupStartDownloadMessage =
  'Download your setup bundle; this file will be used to configure and update your pi-top [4] devices.';
const deviceSetupDownloadStartedMessage =
  'Your download will start shortly... Please wait until it has finished before continuing; this might take a few minutes!';
const deviceSetupLoadToUsbDrivePlugDrivePrompt = (
  <>
    <span style={{ color: '#00AD9F' }}>Connect</span> your USB drive
  </>
);
const deviceSetupLoadToUsbDrivePlugDriveMessage =
  'First, plug a USB drive into your computer. Make sure it has at least 2GB of free space.';
const deviceSetupLoadToUsbDriveCopyFilePrompt = (
  <>
    <span style={{ color: '#00AD9F' }}>Copy</span> your setup file
  </>
);
const deviceSetupLoadToUsbDriveCopyFileMessage = (
  <>
    Open a file browser and copy the downloaded bundle file into the USB drive.
    <br />
    Please ensure the file is named <em>pi-top-usb-setup.tar.gz</em>
  </>
);
const deviceSetupLoadToUsbDriveEjectPrompt = (
  <>
    <span style={{ color: '#00AD9F' }}>Remove</span> your USB drive
  </>
);
const deviceSetupLoadToUsbDriveEjectMessage =
  'Eject the USB drive from your computer.';

const deviceSetupPowerOnPrompt = (
  <>
    Power on your <span style={{ color: '#00AD9F' }}>pi-top[4]</span>
  </>
);
const deviceSetupPlugPowerMessage = (
  <>
    <p>Plug in the Power Supply Unit and power up your pi-top [4].</p>
    <p>Slide the switch to the left until the green light comes up!</p>
  </>
);

// usb drive instructions
const deviceSetupPlugUsbDrivePrompt = (
  <>
    Connect the <span style={{ color: '#00AD9F' }}>USB drive</span>
  </>
);
const deviceSetupPlugUsbDriveMessage = 'Plug the USB drive into your pi-top[4]';
const deviceSetupFollowMiniscreenPrompt = (
  <>
    Look at the <span style={{ color: '#00AD9F' }}>miniscreen</span>
  </>
);
const deviceSetupFollowMiniscreenMessage =
  'After a few seconds, a prompt will be displayed on the pi-top[4] miniscreen. Follow the on-screen instructions and wait for the process to finish. This can take a few minutes!';

// wired instructions
const deviceSetupConnectUsbCablePrompt = (
  <>
    Connect the <span style={{ color: '#00AD9F' }}>USB cable</span>
  </>
);
const deviceSetupConnectCableMessage =
  'Plug one end of the pi-top USB cable to your laptop and the other end to your pi-top[4]';
const deviceSetupGoToPitopLocalPrompt = (
  <>
    Go to <span style={{ color: '#00AD9F' }}>pitop.local</span>
  </>
);
const createDeviceSetupOpenWebPortalMessage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const returnUrl = urlParams.get('return_url') || 'http://192.168.64.1/school';

  return (
    <>
      Now go to{' '}
      <a
        style={{ color: '#00AD9F', textDecoration: 'none' }}
        rel="noreferrer"
        target="_blank"
        href={`${returnUrl}`}
      >
        {returnUrl}
      </a>
      . If that doesn’t work for you, try{' '}
      <a
        style={{ color: '#00AD9F', textDecoration: 'none' }}
        rel="noreferrer"
        target="_blank"
        href="http://pi-top.local"
      >
        pi-top.local/school
      </a>{' '}
      instead.
    </>
  );
};

const deviceUploadFilePrompt = (
  <>
    Upload your <span style={{ color: '#00AD9F' }}>setup file</span>
  </>
);
const deviceSetupUploadSetupFileMessage =
  'Click the upload button and select the setup file you downloaded earlier. The file will be sent to your pi-top[4].';

// ap instructions
const deviceSetupConnecToHotspotPrompt = (
  <>
    Connect to the pi-top{' '}
    <span style={{ color: '#00AD9F' }}>Wi-Fi Hotspot</span>
  </>
);
const deviceSetupConnecToHotspotMessage = (
  <>
    <p>The network ID starts with pi-top and ends with 4 random characters.</p>
    <p>You can find the password from the miniscreen.</p>
    <p>
      If you don&apos;t see this Wi-Fi network, please read{' '}
      <a
        style={{ color: '#00AD9F', textDecoration: 'none' }}
        href="https://knowledgebase.pi-top.com/knowledge/connecting-with-your-pi-top4"
      >
        this article
      </a>
      .
    </p>
  </>
);

const deviceSetupKnowledgeBaseUrl =
  'https://knowledgebase.pi-top.com/knowledge/pi-top-setup-file';

const retakeSubmission = 'Retake';
const retakeSubmissionButtonLabel = 'Trigger Retake';
const retakeSubmissionWarning =
  'This will reset the submission and remove all feedback';

const logInToPurchase = 'Log In to Purchase';

const toSentenceCase = (str) => {
  if (!str) return str;

  // Split into sentences using period, exclamation, or question mark
  return str
    .toLowerCase()
    .replace(/(^|[.!?]\s+)([a-z])/g, (match, separator, char) => {
      return separator + char.toUpperCase();
    });
};

const teacherTraining = 'Teacher Training';

const translateContentTypesToDisplayNames = (projectType) => {
  switch (projectType) {
    case PROJECT_TYPE.pd:
      return teacherTraining;
    default:
      return toSentenceCase(projectType);
  }
};

const translateContentTypesFromDisplayNames = (projectType) => {
  switch (projectType) {
    case teacherTraining:
      return PROJECT_TYPE.pd;
    default:
      return projectType.toLowerCase();
  }
};

const getProjectCardName = (projectType) =>
  translateContentTypesToDisplayNames(projectType).toUpperCase();

const getCourseCardName = (courseType) => {
  switch (courseType) {
    case COURSE_TYPE.lesson:
      return 'STUDENT COURSE';
    case COURSE_TYPE.pd:
      return 'TEACHER COURSE';
    case COURSE_TYPE.assessment:
      return 'EXAM';
    case COURSE_TYPE.guide:
    case COURSE_TYPE.challenge:
    default:
      return 'COURSE';
  }
};

const getCourseCardProjectName = (courseType, count) => {
  switch (courseType) {
    case COURSE_TYPE.pd:
      return `Lesson${count > 1 ? 's' : ''}`;
    default:
      return toSentenceCase(`${courseType}${count > 1 ? 's' : ''}`);
  }
};

export {
  metaTitle,
  foundationKit,
  roboticsKit,
  startBriefMetaTitle,
  startProjectMetaTitle,
  createCourseMetaTitle,
  createAssessmentMetaTitle,
  createSpaceMetaTitle,
  createSpacePlaceholder,
  newCourse,
  newChallenge,
  newAssessment,
  joinSpacePlaceholder,
  loginMetaTitle,
  signUpMetaTitle,
  forgotPasswordEmailSent,
  forgotPasswordMetaTitle,
  forgotPasswordError,
  forgotPasswordEmailNotFoundError,
  resetPasswordMetaTitle,
  resetPasswordSuccess,
  resetPasswordsDontMatch,
  editBriefMetaTitle,
  projectMetaTitle,
  editSpaceMetaTitle,
  dashboardGreetingGuest,
  dashboardGreetingMember,
  joinSpaceInstruction,
  navbarGreeting,
  navbarSignOutButton,
  placeholderLoading,
  placeholderError,
  placeholderCreating,
  placeholderCreateError,
  placeholderAddError,
  defaultErrorMessage,
  errorDialogMessage,
  briefBuilderTitle,
  briefBuilderExplanation,
  fileTooLargeErrorMessage,
  noFileProvidedErrorMessage,
  youtubeInputLabel,
  youtubeIdErrorMessage,
  wistiaInputLabel,
  wistiaIdErrorMessage,
  noImageUploadUrlError,
  unsupportedFileTypeError,
  imageUploadErrorMessage,
  supportedExtensionsListMessage,
  existingReaderGroupError,
  readerGroupError,
  noLockedVersions,
  removeItemTitle,
  remvoeChallengeFromCourse,
  removeItemWarning,
  downloadFileWarning,
  deleteFileWarning,
  featureFlagSignUpNote,
  logInLabel,
  signUpLabel,
  searchTagHelp,
  challengeModelName,
  challengeModelNamePlural,
  emptyChallengesMemberPlaceholder,
  emptyChallengesAdminPlaceholder,
  draftHasChanges,
  assessmentModelName,
  assessmentModelNamePlural,
  projectsPath,
  briefsPath,
  myContentPath,
  codeboxPath,
  groupModelName,
  groupModelNamePlural,
  schoolModelName,
  schoolModelNamePlural,
  schoolMemberModelName,
  schoolMemberModelNamePlural,
  addSchoolMembersDescription,
  schoolsPath,
  tagModelName,
  tagModelNamePlural,
  addTagsDescription,
  allTagsAddedDescription,
  removeTagDescription,
  errorRemovingTag,
  emptyTagsTab,
  deviceModelName,
  deviceModelNamePlural,
  addDevicesDescription,
  allDevicesAddedDescription,
  removeDeviceDescription,
  errorRemovingDevice,
  emptyDevicesTab,
  removeProductTitle,
  addProductTitle,
  realmModelName,
  realmModelNamePlural,
  addRealmsDescription,
  allRealmsAddedDescription,
  removeRealmDescription,
  errorRemovingRealm,
  emptyRealmsTab,
  groupsPath,
  deleteGroupWarning,
  submissionCollaboratorName,
  submissionCollaboratorNamePlural,
  submissionCollaborateName,
  challengeEditorName,
  challengeEditorNamePlural,
  challengeEditName,
  courseEditorName,
  courseEditorNamePlural,
  courseEditName,
  memberName,
  memberNamePlural,
  adminName,
  adminNamePlural,
  projectTitleName,
  briefTitleName,
  groupTitleName,
  removeCollaboratorText,
  removeMemberText,
  removingText,
  youName,
  addMemberExplanations,
  addSubmissionCollaboratorDescription,
  addSubmissionCollaboratorExplanation,
  addCourseEditor,
  addChallengeEditor,
  addEditorExplanation,
  addMemberDescription,
  addMemberByName,
  addMemberByNamePlaceholder,
  addMembersByCode,
  addMembersByCsv,
  studentOnlyLabel,
  uploadMembersCsv,
  previewLinkTitle,
  view,
  versionName,
  versionNamePlural,
  newVersionPrompt,
  publishName,
  publishPresentParticiple,
  commentName,
  commentNamePlural,
  newCommentPrompt,
  reportCommentWarningText,
  reportButtonTitle,
  reportDialogTitle,
  reportSubmitButton,
  reportTitle,
  reportWarningText,
  reportSubmittedTitle,
  reportSubmittedWarningText,
  deleteBlockButtonTitle,
  deleteBlockDialogTitle,
  deleteBlockDialogSubmitButton,
  deleteButtonText,
  sectionName,
  sectionNamePlural,
  clickToAddNewSection,
  clickToImportSection,
  importSectionButtonText,
  importSectionErrorMessage,
  taskName,
  taskNamePlural,
  taskResponseName,
  taskResponseNamePlural,
  mockTaskResponseLoginPrompt,
  exploreAddToClassLoginPrompt,
  deleteTaskButtonTitle,
  deleteTaskDialogTitle,
  deleteTaskDialogSubmitButton,
  blockModelName,
  blockModelNamePlural,
  interestFormDesc,
  interestFormTitle,
  alreadyHaveAccount,
  shareProjectButton,
  shareProjectDialogTitle,
  shareProjectDialogSearchLabel,
  assignDialogTitle,
  assignDialogSearchLabel,
  assignDialogLoadingMessage,
  assignDialogNoClassesMessage,
  assignDialogCheckGroupName,
  assignDialogConfirmActionLabel,
  assignErrorMessage,
  addCourseChallengeDialogCheckGroupName,
  addCourseChallengeDialogConfirmActionLabel,
  addCourseChallengeDialogNoCoursesMessage,
  addCourseChallengeDialogSearchLabel,
  addCourseChallengeButton,
  addCourseChallengeDialogTitle,
  addCourseChallengeDialogLoadingMessage,
  addCourseChallengeErrorMessage,
  addSubmissionDialogTitle,
  addSubmissionDialogSearchLabel,
  addSubmissionDialogConfirmLabel,
  addSubmissionErrorMessage,
  shareProjectDialogWontBePublicMessage,
  shareProjectDialogWillBePublicMessage,
  addProjectToPublicCourseWarningMessage,
  sharingErrorMessage,
  projectIsPublicMessage,
  privacyPolicyRequest,
  newUrlParam,
  searchPlaceholder,
  assignmentMetaTitle,
  assignmentModelName,
  assignmentModelNamePlural,
  assignmentsPath,
  submissionModelName,
  submissionModelNamePlural,
  submissionsPath,
  submissionMetaTitle,
  inviteModelName,
  standardName,
  standardNamePlural,
  removeStandardText,
  emptyStandardsTab,
  emptyStandardsList,
  emptyMultiSelectList,
  resetPasswordsInvalidToken,
  explorePageName,
  libraryPath,
  dashboardPath,
  submissionsNamePlural,
  placeholderNoRecord,
  consoleLabel,
  filesLabel,
  videoLabel,
  novncLabel,
  htmlLabel,
  urlHelpText,
  createNewLink,
  linkAriaLabel,
  projectUpdateHelpText,
  dontHaveInviteCodeText,
  orStartYour,
  startYour,
  hasAMembershipCode,
  cancelMembership,
  membershipLoginMessage,
  membershipActivated,
  membershipCancelled,
  moreInformation,
  salesEmail,
  paywallPlaceholder,
  paywallPlaceholderLAUSD,
  membershipCodeErrorMessage,
  cancelMembershipErrorMessage,
  membershipCodeLabel,
  accessClassTabs,
  activateMembershipTitle,
  noneMembershipStatusTitle,
  fullMembershipStatusTitle,
  trialMembershipStatusTitle,
  expiredMembershipStatusTitle,
  activateMembershipHintText,
  toContinueUsingAllTheFeaturesHintText,
  getRemainingMembershipTitlePart,
  getExpiredAgoMembershipTitlePart,
  activateLicenseTitle,
  activateLicenseHint,
  activateLicenseInputLabel,
  activateLicenseSubmit,
  licenseActivated,
  licenseCodeErrorMessage,
  activatedContentLicenseDescription,
  activatedContentLicenseShareInfo,
  activatedContentShareExplanation,
  activatedContentShareExplanationPage,
  skip,
  youCanDoThisLater,
  youMustLoginThroughGoogleClassroomOrClever,
  external,
  externalClassEditingLockedMessage,
  blockName,
  uploadVideoInstructions,
  uploadImageInstructions,
  uploadFileErrorMessage,
  uploadFileInstructions,
  teacherResource,
  teacherResourcePlural,
  emptyTeacherResourcesTab,
  areYourSureResources,
  removeTeacherResource,
  notificationsEmpty,
  notificationMessages,
  tutorialsPageName,
  tutorialsPath,
  onboardingPageName,
  onboardingPath,
  hardwareOnboardingQuestionsNone,
  tutorialConnectionErrorHTML,
  codeErrorMessage,
  tutorialCodeResetText,
  pressConnectToGetStarted,
  idTechRegistrationUrl,
  tutoringDialogTitle,
  tutoringDialogDescription,
  tutoringFormSubmissionDescription,
  tutoringIntro,
  tutoringTechChallenges,
  idTechBookingInstruction,
  membershipCodeUrlParamName,
  updagradeMembership,
  classesListUpgradeAccount,
  backLabel,
  furtherLinkRunError,
  furtherLinkConnectError,
  furtherLinkBluetoothConnectError,
  furtherLinkNoBluetoothAdapterError,
  furtherLinkUploadError,
  furtherLinkName,
  sdkName,
  systemSoftwareNotFound,
  systemSoftwareBehindMajor,
  systemSoftwareBehindMinor,
  systemSoftwareBehindPatch,
  updateFurtherLinkTitle,
  updateFurtherLinkCallToAction,
  updateFurtherLinkWaitForUpdate,
  updateFurtherLinkMoreInformation,
  updateFurtherLinkComplete,
  addToClassLabel,
  addedToClassLabel,
  fourOhFourTitle,
  fourOhFourLoggedInMessage,
  fourOhFourGuestMessage,
  errorTitle,
  errorRefreshMessage,
  errorRefreshButtonTitle,
  errorRetryMessage,
  errorRetryButtonTitle,
  errorFooter,
  clickGuardLabel,
  mockBlockLoginPrompt,
  redirectLabel,
  alreadyAssignedToClassTitle,
  alreadyAssignedToClassMessage,
  publishChangesButton,
  defaultEditableTitlePlaceholder,
  defaultEditableDescriptionPlaceholder,
  sectionTitlePlaceholder,
  sectionCompleteCheckboxLabel,
  sectionCompleteMessage,
  addTaskButtonLabel,
  textTaskDetailPlaceholder,
  connect,
  connected,
  disconnect,
  disconnected,
  connecting,
  connectingCancel,
  connectDeviceDropdownLabel,
  taskTypeMenuButtonText,
  taskTypeMenuDropdownLabel,
  webpageTaskDetailInputLabel,
  webpageTaskDetailInputPlaceholder,
  makeCopy,
  removeItemPostfix,
  addOtherCollaboratorsLabel,
  addOtherEditorsLabel,
  cloneLoading,
  cloneSuccess,
  cloneError,
  publishLoading,
  publishSuccess,
  publishError,
  courseModelName,
  courseModelNamePlural,
  coursesPath,
  navigationPreviousLink,
  navigationNextLink,
  courseMetaTitle,
  draftSubmissonAddMemberInstructionText,
  stopCodeToEditTooltipLabel,
  askSignupTitle,
  askSignupDescription,
  newToFurtherLabel,
  errorMessages,
  piTopThree,
  piTopFour,
  marketingBannerTitlePitop,
  marketingBannerStudentBlurbPitop,
  marketingBannerTeacherBlurbPitop,
  marketingBannerSignUpTextPitop,
  marketingBannerTitleMakerbot,
  marketingBannerStudentBlurbMakerbot,
  marketingBannerTeacherBlurbMakerbot,
  marketingBannerPurchaseLicenseMakerbot,
  marketingBannerTitleMerge,
  marketingBannerBlurbMerge,
  marketingBannerLearnMoreTextMerge,
  marketingBannerTitle3doodler,
  marketingBannerBlurb3doodler,
  marketingBannerLearnMoreText3doodler,
  marketingBannerTitlePLTW,
  marketingBannerBlurbPLTW,
  marketingBannerLearnMoreTextPLTW,
  marketingBannerTitleDatabot,
  marketingBannerBlurbDatabot,
  marketingBannerLearnMoreTextDatabot,
  marketingBannerTitleLausd,
  marketingBannerBlurbLausd,
  marketingBannerLearnMoreTextLausd,
  notStarted,
  inProgress,
  done,
  confirm,
  deleteAssignmentErrorMsg,
  deleteAssignmentTitle,
  deleteAssignmentWarningText,
  explainPublicVisibility,
  explainShareableVisibility,
  explainPrivateVisibility,
  editChallengeVisibilityDialogTitle,
  editChallengeVisibilityDialogButtonText,
  editChallengeVisibilityDialogErrorMessage,
  visibilityOfCourseMsg,
  editVisibilityButton,
  editChallengeVisibilityDialogInstruction,
  editCourseVisibilityDialogErrorMessage,
  editCourseVisibilityDialogInstruction,
  editCourseVisibilityDialogTitle,
  createCourseErrorMessage,
  toggleAssignmentSubmissionsButtonText,
  connectFlyoutToggleButtonAriaLabel,
  pageModeDropdownLabel,
  gradeFlyoutToggleAriaLabel,
  gradeFlyoutLoading,
  gradeFlyoutError,
  gradeFlyoutSubmissionNotFound,
  manageAccountLink,
  submitWorkLabel,
  submittedMessage,
  notSubmittedMessage,
  loadingSubmitted,
  errorLoadingSubmitted,
  completeWorkLabel,
  completedMessage,
  notCompletedMessage,
  loadingCompleted,
  errorLoadingCompleted,
  makerbotActivateWelcome,
  pitopActivateWelcome,
  activatePath,
  activateLoading,
  activateLogin,
  activateLoginDescription,
  activateChooseSchool,
  activateChooseSchoolDescription,
  activateCreateSchool,
  activateSelectSchoolPlaceholder,
  activateEnterCode,
  activateEnterCodeDescription,
  activateSuccessTitle,
  upgradeSuccessTitle,
  activateUpgradeMessage,
  makerbotActivateSuccessDescription,
  pitopActivateSuccessDescription,
  activateAdditionalLicenses,
  activateGoToDashboard,
  schoolLicensesMustBeAdmin,
  schoolActivateLicenseButtonLabel,
  schoolManageLicensesButtonLabel,
  schoolLicensesSummaryTitle,
  schoolLicenseListTitle,
  schoolNoLicensesMessage,
  schoolLicensesListStatusColumn,
  schoolLicensesListNameColumn,
  schoolLicensesListDescriptionColumn,
  schoolLicensesListCodeColumn,
  schoolLicensesListExpiresColumn,
  schoolLicensesListRenewColumn,
  schoolActivateLicenseTitle,
  schoolActivateLicenseHint,
  schoolActivateLicenseInputLabel,
  schoolActivateLicenseSubmit,
  schoolActivateLicenseLoading,
  schoolNoLicensesWarning,
  schoolOverAllocatedWarning,
  schoolUnpaidLicensesWarning,
  missingContentAccessWarning,
  deviceSetupPath,
  deviceSetupWithUsbDrivePath,
  deviceSetupWithUsbCablePath,
  deviceSetupWithApPath,
  deviceSetupDownloadPrompt,
  deviceSetupLoading,
  deviceSetupLogin,
  deviceSetupDownloadLabel,
  deviceSetupStartDownloadMessage,
  deviceSetupDownloadStartedMessage,
  deviceSetupKnowledgeBaseUrl,
  continueLabel,
  downloadFinishedLabel,
  setupAnotherDeviceLabel,
  deviceSetupLoadToUsbDrivePlugDrivePrompt,
  deviceSetupLoadToUsbDriveCopyFilePrompt,
  deviceSetupLoadToUsbDriveEjectPrompt,
  deviceSetupLoadToUsbDrivePlugDriveMessage,
  deviceSetupLoadToUsbDriveCopyFileMessage,
  deviceSetupLoadToUsbDriveEjectMessage,
  deviceSetupPlugPowerMessage,
  deviceSetupPlugUsbDriveMessage,
  deviceSetupFollowMiniscreenMessage,
  deviceSetupConnectCableMessage,
  createDeviceSetupOpenWebPortalMessage,
  deviceSetupUploadSetupFileMessage,
  deviceSetupPowerOnPrompt,
  deviceSetupPlugUsbDrivePrompt,
  deviceSetupFollowMiniscreenPrompt,
  deviceSetupConnectUsbCablePrompt,
  deviceSetupGoToPitopLocalPrompt,
  deviceUploadFilePrompt,
  deviceSetupConnecToHotspotPrompt,
  deviceSetupConnecToHotspotMessage,
  retakeSubmission,
  retakeSubmissionButtonLabel,
  retakeSubmissionWarning,
  logInToPurchase,
  getProjectCardName,
  getCourseCardName,
  getCourseCardProjectName,
  translateContentTypesToDisplayNames,
  translateContentTypesFromDisplayNames,
};
