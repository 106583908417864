import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import { buildChallengeUrl } from '../../utils/urlBuilder';
import styles from './NotificationCard.module.css';
import {
  GetNotificationProjectLink,
  GetNotificationProjectLinkVariables,
} from './types/GetNotificationProjectLink';

const GET_NOTIFICATION_PROJECT_LINK = gql`
  query GetNotificationProjectLink($projectId: String!) {
    project(id: $projectId) {
      __typename
      id
      draftVersion {
        __typename
        title
      }
      readerVersion {
        __typename
        title
      }
    }
  }
`;

type Props = {
  projectId: string;
};

const ProjectLink = ({ projectId }: Props) => {
  const { data, loading, error } = useQuery<
    GetNotificationProjectLink,
    GetNotificationProjectLinkVariables
  >(GET_NOTIFICATION_PROJECT_LINK, { variables: { projectId } });
  const { project } = data || {};

  const link = buildChallengeUrl(projectId, { edit: true });

  const version = project?.draftVersion || project?.readerVersion;

  if (!project || !version || loading || error) {
    return (
      <Link to={link}>
        &quot;
        <span className={styles.unknownValue}>project name</span>
        &quot;
      </Link>
    );
  }

  return (
    <Link to={link}>
      &quot;
      {version.title}
      &quot;
    </Link>
  );
};

export default ProjectLink;
