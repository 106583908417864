import {
  briefsPath,
  projectsPath,
  submissionsPath,
  codeboxPath,
  coursesPath,
  groupsPath,
  assignmentsPath,
} from '../strings';

export const buildProjectUrl = (
  id: string,
  options?: {
    courseId?: string;
    classId?: string;
    submissionId?: string;
    edit?: boolean;
    preview?: boolean;
    grade?: boolean;
  }
): string =>
  [
    options?.courseId ? `/${coursesPath}/${options?.courseId}` : '',
    options?.classId ? `/${groupsPath}/${options?.classId}` : '',
    `/${projectsPath}/${id}`,
    options?.edit ? '/edit' : '',
    options?.preview ? '/preview' : '',
    options?.grade ? '/grade' : '',
    options?.submissionId ? `?submissionId=${options.submissionId}` : '',
  ].join('');

export const buildChallengeUrl = (
  id: string,
  options?: {
    courseId?: string;
    classId?: string;
    submissionId?: string;
    edit?: boolean;
    preview?: boolean;
    grade?: boolean;
  }
): string =>
  [
    options?.courseId ? `/${coursesPath}/${options?.courseId}` : '',
    options?.classId ? `/${groupsPath}/${options?.classId}` : '',
    `/${briefsPath}/${id}`,
    options?.edit ? '/edit' : '',
    options?.preview ? '/preview' : '',
    options?.grade ? '/grade' : '',
    options?.submissionId ? `?submissionId=${options.submissionId}` : '',
  ].join('');

export const buildSubmissionUrl = (
  id: string,
  options?: {
    courseId?: string;
    classId?: string;
    edit?: boolean;
  }
): string =>
  [
    options?.courseId ? `/${coursesPath}/${options?.courseId}` : '',
    options?.classId ? `/${groupsPath}/${options?.classId}` : '',
    `/${submissionsPath}/${id}`,
    options?.edit ? '/edit' : '',
  ].join('');

export const buildCodeboxUrl = (
  id: string,
  options?: {
    coderFileName?: string;
    terminal?: boolean;
    settings?: boolean;
  }
): string =>
  [
    `/${codeboxPath}/${id}`,
    options?.coderFileName ? `/${options?.coderFileName}` : '',
    '?',
    options?.terminal ? 'terminal=1&' : '',
    options?.settings ? 'settings=1&' : '',
  ].join('');

export const buildGroupUrl = (id: string): string => `/${groupsPath}/${id}`;

export const buildAssignmentUrl = (id: string): string =>
  `/${assignmentsPath}/${id}`;

export const buildCourseUrl = (
  id: string,
  options?: {
    edit?: boolean;
  }
): string => [`/${coursesPath}/${id}`, options?.edit ? '/edit' : ''].join('');
